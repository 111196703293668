<template>
  <section class="content">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <div class="row form-horizontal">
              <div class="col-auto">
                <label class="control-label my-2 ml-2">
                  <h6>Filter Pencarian</h6>
                </label>
              </div>
              <div class="col-auto">
                <select class="form-control" @change="dataType($event)">
                  <option value="pickupTime">Dibuat Antara</option>
                  <option value="updatedOn">Update Antara</option>
                </select>
              </div>
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      style="background-color: #fff"
                    >
                      <span><span class="fa fa-calendar"></span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-info dropdown-icon dropdown-toggle"
                  @click="selectStatus"
                >
                  Status
                </button>
              </div>
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover" ref="tableuser" id="tblriwayat">
            <thead>
              <tr>
                <th rowspan="2">NO. ORDER</th>
                <th rowspan="2">NO. RESI</th>
                <th rowspan="2">PENGIRIM</th>
                <th rowspan="2">KURIR</th>
                <th rowspan="2">PENERIMA</th>
                <th rowspan="2">TELEPON</th>
                <th rowspan="2">TUJUAN</th>
                <th rowspan="2">TANGGAL</th>
                <th rowspan="2">BERAT(KG)</th>
                <th rowspan="2">NILAI BARANG</th>
                <th rowspan="2">NILAI COD</th>
                <th rowspan="2">ONGKIR AWAL</th>
                <th colspan="6">INFO SHIPPER</th>
                <th colspan="2">INFO BOSCOD</th>
                <th colspan="3">INFO KURIR</th>
                <th rowspan="2">STATUS</th>
                <th rowspan="2">ACTION</th>
              </tr>
              <tr>
                <th>DISKON</th>
                <th>ONGKIR + PPN</th>
                <th>ASURANSI</th>
                <th>COD FEE + PPN</th>
                <th>TOTAL BIAYA</th>
                <th>COD DITERIMA</th>
                <th>CASHBACK BC</th>
                <th>FEE COD BC</th>
                <th>FEE COD KRR</th>
                <th>DITAGIH</th>
                <th>TERIMA PAYMENT</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            <tfoot>
              <tr>
                <th colspan="9"></th>
                <th></th>
                <th colspan="2"></th>
                <th></th>
                <th colspan="3"></th>
                <th></th>
                <th colspan="8"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <Historyfilter :show="show" :sTicket="false" :sOther="false" v-model="filter" v-on:change="updateFilter" />
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import moment from "moment";

export default {
  name: "Riwayat",
  data() {
    return {
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah User",
      form: {
        name: "",
        username: "",
        email: "",
      },
      downloading: false,
      show: false,
      filter: {},
      dt1: moment().startOf("month"),
		  dt2: moment(),
      data_type: "pickupTime",
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    Historyfilter,
  },
  watch:{
    $route (to, from){
      if(to.path === "/payment-admin/all")
      {
        sessionStorage.filterData='';
		sessionStorage.filterStatus = '';
        this.filter = {};
        this.table.api().ajax.reload();
      }
    }
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

    if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');

    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }

    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    } else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }

    this.filter.datatype = this.data_type;
    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    downloadXlsx: function (e) {
      this.downloading = true;
      var filter = this.filter;
      filter.d1 = this.dateStart;
      filter.d2 = this.dateEnd;
      filter.datatype = this.data_type;

      var data = Object.keys(filter)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
        )
        .join("&");
      authFetch("/report/payment/excell", {
        method: "POST",
        body: data,
      })
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "data.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    updateFilter: function (e) {
      this.filter = e;
      console.log(this.filter);
      this.table.api().ajax.reload();
    },
    dataType(event) {
      this.data_type = event.target.value;
      this.filter.datatype = this.data_type;
      this.table.api().ajax.reload();
    },
    onDate() {
      this.table.api().ajax.reload();
    },
    selectStatus: function () {
      this.show = !this.show;
      console.log("click", this.show);
    },
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "view") {
          this.$router.push({
            path: "/payment-admin/detail/" + e.target.dataset.id,
          });
        }
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
					format: 'DD/MM/YYYY'
				},
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
      }
    );
    console.log("logo", self.getKurirLogo("jne"));
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    this.table = createTable(e.tableuser, {
      title: "Data Riwayat",
      roles: this.$route.params.roles,
      ajax: "/payment/admin/riwayat",
      frame: false,
      toolbarEl: e.toolbar,
      // lengthChange: false,
      lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
      lengthChange: true,
      filter: true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
      paramData: function (d) {
        // d.cod = self.filter.cod ? 1 : 0;
        // d.noncod = self.filter.noncod ? 1 : 0;
        // d.ts0 = self.filter.ts0 ? 1 : 0;
        // d.ts1 = self.filter.ts1 ? 1 : 0;
        // d.ts2 = self.filter.ts2 ? 1 : 0;
        // d.ts3 = self.filter.ts3 ? 1 : 0;
        // d.ts4 = self.filter.ts4 ? 1 : 0;
        // d.d1 = self.dateStart;
        // d.d2 = self.dateEnd;
        // d.datatype = self.data_type;
        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
            d[key] = filter[key] === false ? 0: filter[key];
        }
      },
      columns: [
        { data: "order_code" },
        { data: "billNo" },
        {
          data: "shipperName",
          title: isShipper ? "Asal Gudang" : "SHIPPER",
          render: function (data, type, row, meta) {
            return row.senderName + "<br>" + row.senderCity;
          },
        },
        {
          data: "courierCode",
          visible: isKurir ? false : true,
          render: function (data, type, row, meta) {
            var logo = data.toLowerCase();
            return '<img height="16" src="' + self.getKurirLogo(logo) + '" />';
          },
        },
        {
          data: "destName",
          title: "PENERIMA",
          render: function (data, type, row, meta) {
            return row.destName;
          },
        },
        { data: "destTel" },
        {
          data: "destCity",
          render: function (data, type, row, meta) {
            return row.kecamatan + ", " + row.destCity;
          },
        },
        {
          data: "lastActivity",
          render: function (data, type, row, meta) {
            return self.data_type == "updatedOn"
              ? row.updatedOn
              : row.pickupTime;
          },
        },
        { data: "weight" },
        {
          data: "item_value",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.item_value);
          },
        },
        {
          data: "codValue",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
        {
          data: "ongkir_dasar",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
        {
          data: "nilai_ongkir",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
        {
          data: "insurance_fee",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
        {
          data: "fee_ppn",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.feeCod+row.ppnCod);
          },
        },
        {
          data: "totalbiaya",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(data);
          },
        },
        {
          data: "cod_diterima",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(data);
            // return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
					data: "pickupStatus",
					render: function (data, type, row, meta) {
						if (row.cancel == 1)
							return '<span class="badge badge-danger">Cancel</span>';
						else if (row.pickupStatus == 0)
							return '<span class="badge badge-inverse">DRAFT</span>';
						else {
							if (row.trackingStatus == 0)
                if(row.order_type == 1){
                  return '<span class="badge badge-secondary">Menunggu Drop</span>';
                } else {
                  return '<span class="badge badge-secondary">Menunggu Pickup</span>';
                }
							else if (row.trackingStatus == 1)
								return '<span class="badge badge-warning">Proses</span>';
							else if (row.trackingStatus == 2)
								return '<span class="badge badge-success">Terkirim</span>';
							else if (row.trackingStatus == 3)
								return '<span class="badge badge-warning">Proses Retur</span>';
							else if (row.trackingStatus == 4) {
								if (row.retStatus == 1) return '<span class="badge badge-success">Retur Diterima</span>';
								else if (!row.retStatus && !row.retClaim)
									return '<span class="badge badge-info">Retur Diserahkan</span>';
								else if (row.retClaim == 1)
									return '<span class="badge badge-danger">Retur Diclaim</span>';
								else if (row.retClaim == 2)
									return '<span class="badge badge-success">Retur Claim disetujui</span>';
								else if (row.retClaim == 3)
									return '<span class="badge badge-danger">Retur Claim ditolak</span>';
							} else if (row.trackingStatus == 5)
								return '<span class="badge badge-danger">Bermasalah</span>';
						}
					},
				},
        {
          data: "action",
          sortable: false,
          render: function (data, type, row, meta) {
            return (
              `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="` +
              row.order_no +
              `"><i class="fas fa-eye"></i> Lihat detail</button>
              </div>`
            );
          },
        },
      ],
      filterBy: [0, 1, 2, 4, 5, 6],
      rowCallback: function (row, data) {
        // $("td:eq(0)", row).html(
        //   '<a class="link link-order text-info" style="cursor:pointer;" data-id="' +
        //     data.order_code +
        //     '">' +
        //     data.order_code +
        //     "</a>"
        // );
      },
      footerCallback:function ( row, data, start, end, display ){
        var api = this.api(), data;
            // Total over this page
            var nilaicod;
            nilaicod = 
				api
                .column(10)
                .data()
				.reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0);

            var ongkir;
            ongkir = api
                .column(13)
                .data()
                .reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0 );

            var biayacod;
            biayacod = api
                .column(16, {page: 'current'})
                .data()
                .reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0 );

            var grandtotal;
            grandtotal = api
                .column(17)
                .data()
                .reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0 );

            // Update footer
        $( api.column(10).footer() ).html(
        'Rp'+ formatCurrency(nilaicod)
				);
				$( api.column(13).footer() ).html(
					'Rp'+ formatCurrency(ongkir)
				);
				$( api.column(16).footer() ).html(
					'Rp'+ formatCurrency(biayacod)
				);
				$( api.column(17).footer() ).html(
					'Rp'+ formatCurrency(grandtotal)
				);
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			}
    });
  },
};
</script>
<style scoped>
thead th {
  vertical-align: middle !important;
  text-align: center !important;
}
th {
  white-space: nowrap;
}
.dataTables_length{padding-top: 0.5em;}
</style>